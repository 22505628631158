import "./css/App.css";
import { Host } from "./project/components";

function App() {
  return (
    <div className="App">
      <Host />
    </div>
  );
}
export default App;
